<template>
  <div class="container">
    <div class="success-page">
      <div class="success-page__document-background">
        <div class="success-page__document document">
          <div class="document__row">
            <div class="document__key document__key--title">
              {{ $t('application_page.general_information') }}
            </div>
            <div class="document__value"></div>
          </div>
          <div class="document__row">
            <div class="document__key">
              {{ $t('application_page.application_code') }}
            </div>
            <div class="document__value">{{ form_code }}</div>
          </div>
          <div class="document__row">
            <div class="document__key">
              {{ $t('data_naming.full_name') }}
            </div>
            <div class="document__value">{{ full_name }}</div>
          </div>
          <div class="document__row">
            <div class="document__key">{{ $t('data_naming.nationality') }}</div>
            <div class="document__value">
              {{ nationality_country.toUpperCase() }}
            </div>
          </div>
          <div class="document__row">
            <div class="document__key">
              {{ $t('data_naming.residence_country') }}
            </div>
            <div class="document__value">
              {{ residence_country.toUpperCase() }}
            </div>
          </div>
          <div class="document__row">
            <div class="document__key">{{ $t('data_naming.email') }}</div>
            <div class="document__value">
              {{ email }}
            </div>
          </div>
          <div class="document__row">
            <div class="document__key">
              {{ $t('data_naming.phone_number') }}
            </div>
            <div class="document__value">{{ phone_number }}</div>
          </div>
          <div class="document__row">
            <div class="document__key">
              {{ $t('data_naming.alternate_phone_number') }}
            </div>
            <div class="document__value">{{ alternative_phone_number }}</div>
          </div>

          <div class="document__row document__row--second">
            <div class="document__key document__key--title">
              {{ $t('application_page.trip_information') }}
            </div>
            <div class="document__value"></div>
          </div>
          <div class="document__row">
            <div class="document__key">
              {{ $t('data_naming.port_of_departure') }}
            </div>
            <div class="document__value">
              {{ port_of_departure }}
            </div>
          </div>
          <div class="document__row">
            <div class="document__key">
              {{ $t('data_naming.port_of_arrival') }}
            </div>
            <div class="document__value">
              {{ port_of_arrival.toUpperCase() }}
            </div>
          </div>
          <div class="document__row">
            <div class="document__key">
              {{ $t('data_naming.arrival_date') }}
            </div>
            <div class="document__value">{{ arrival_date }}</div>
          </div>
          <div class="document__row">
            <div class="document__key">
              {{ $t('data_naming.departure_date') }}
            </div>
            <div class="document__value">{{ departure_date }}</div>
          </div>
          <div class="document__row">
            <div class="document__key">
              {{ $t('data_naming.passport_number') }}
            </div>
            <div class="document__value">{{ passport_number }}</div>
          </div>
          <div class="document__row">
            <div class="document__key">
              {{ $t('data_naming.type_of_transport') }}
            </div>
            <div class="document__value">
              {{ type_of_transport.toUpperCase() }}
            </div>
          </div>
          <div class="document__row">
            <div class="document__key">
              {{ $t('data_naming.transport_number') }}
            </div>
            <div class="document__value">{{ transport_number }}</div>
          </div>
          <div class="document__row">
            <div class="document__key">
              {{ $t('data_naming.trip_target') }}
            </div>
            <div class="document__value">{{ trip_aim.toUpperCase() }}</div>
          </div>
          <div class="document__row">
            <div class="document__key">
              {{ $t('data_naming.accommodation_address') }}
            </div>
            <div class="document__value">{{ accommodation_address }}</div>
          </div>
        </div>
        <div class="success-page__qr-code">
          <div class="qr-code">
            <img :src="qr_code_image_url" class="qr-code__img" />
            <div class="qr-code__qr-number">{{ form_code }}</div>
            <div class="qr-code__btns-wrapper">
              <a
                :href="application_pdf_url"
                target="_blank"
                class="qr-code__btn qr-code__pdf-download-btn"
              >
                {{ $t('application_page.buttons.download_pdf') }}
              </a>
              <a
                v-if="has_uploaded_pcr_test_file"
                :href="pcr_test_file_url"
                class="qr-code__btn qr-code__pcr-download-btn"
                target="_blank"
              >
                {{ $t('application_page.buttons.download_pcr_test') }}
              </a>
              <a
                v-if="has_uploaded_certificate_file"
                :href="certificate_file_url"
                class="qr-code__btn btn__certificate"
                target="_blank"
              >
                {{ $t('application_page.buttons.download_certificate') }}
              </a>

              <a
                :href="apple_wallet_pass_url"
                class="qr-code__btn qr-code__add-wallet-btn"
              >
                <svg-icon style="margin: 0 5px 0" name="wallet"></svg-icon>
                {{ $t('application_page.buttons.add_to_apple_wallet') }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="info-block">
        <div class="info-block__text">
          {{ $t('application_page.any_information') }}
        </div>
        <a
          href="https://pcr-forms.bazucompany.com/"
          class="qr-code__btn info-block__btn"
        >
          {{ $t('application_page.fill_to_form') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      modal: false,
      form_code: null,
      full_name: null,
      email: null,
      nationality_country: null,
      residence_country: null,
      phone_number: null,
      alternative_phone_number: '-',
      port_of_departure: null,
      port_of_arrival: null,
      arrival_date: null,
      departure_date: '-',
      passport_number: null,
      type_of_transport: null,
      transport_number: null,
      trip_aim: null,
      accommodation_address: null,
      public_url: null,
      qr_code_image_url: null,
      application_pdf_url: null,
      has_uploaded_pcr_test_file: false,
      has_uploaded_certificate_file: false,
      pcr_test_file_url: null,
      certificate_file: null,
      apple_wallet_pass_url: null,
      certificate_file_url: null,
    };
  },
  computed: {},
  mounted() {
    let data;
    this.$store
      .dispatch('getFormData', this.$route.params.uuid)
      .then((formData) => {
        data = formData.data.data.form_application;
        console.log(data);
        this.form_code = data.form_code;
        this.full_name = data.full_name;
        this.email = data.email;
        this.nationality_country = data.nationality_country;
        this.residence_country = data.residence_country;
        this.phone_number = data.phone_number;
        if (data.alternative_phone_number) {
          this.alternative_phone_number = data.alternative_phone_number;
        }
        this.port_of_departure = data.port_of_departure;
        this.port_of_arrival = data.port_of_arrival;
        this.arrival_date = data.arrival_date;
        if (data.departure_date !== null) {
          this.departure_date = data.departure_date;
        }
        this.passport_number = data.passport_number;
        this.type_of_transport = data.type_of_transport;
        this.transport_number = data.transport_number;
        this.trip_aim = data.trip_aim;
        this.accommodation_address = data.accommodation_address;
        this.public_url = data.public_url;
        this.qr_code_image_url = data.qr_code_image_url;
        this.application_pdf_url = data.application_pdf_url;
        this.has_uploaded_pcr_test_file = data.has_uploaded_pcr_test_file;
        this.has_uploaded_certificate_file = data.has_uploaded_certificate_file;
        this.certificate_file_url = data.certificate_file_url;
        this.pcr_test_file_url = data.pcr_test_file_url;
        this.apple_wallet_pass_url = data.apple_wallet_pass_url;
      });
  },
  methods: {},
};
</script>
<style lang="sass" scoped>
.container
  display: flex
  width: 100%
  margin: 0 auto

.success-page
  display: flex
  justify-content: space-between
  flex-direction: column
  align-items: center
  width: 100%
  max-width: 1296px
  padding: 48px
  margin-bottom: 72px
  border: 1px solid var(--border-color-grey)

  @media screen and (max-width: 1199px)
    padding: 32px
  @media screen and (max-width: 767px)
    padding: 0
    border: none
  @media screen and (max-width: 460px)
    margin-bottom: 40px

.success-page__document-background
  position: relative
  display: flex
  justify-content: space-between
  width: 100%
  padding: 40px
  background-color: #fff
  box-shadow: 0px 8px 20px rgba(26, 26, 26, 0.06)
  margin-bottom: 60px

  @media screen and (max-width: 1199px)
    padding: 32px

  @media screen and (max-width: 767px)
    flex-direction: column
    margin-top: 22px

.info-block
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center

.success-page__document
  display: flex
  flex-direction: column
  gap: 24px

  @media screen and (max-width: 767px)
    margin-bottom: 24px

  @media screen and (max-width: 460px)
    gap: 15px
    align-items: center

.document__row
  display: flex
  gap: 15px

  @media screen and (max-width: 375px)
    gap: 0

.document__key
  width: 230px
  font-size: 16px
  line-height: 20px
  font-weight: bold
  text-transform: uppercase

  @media screen and (max-width: 1199px)
    font-size: 14px
    width: 200px

  @media screen and (max-width: 460px)
    font-size: 10px
    width: 150px
    line-height: 13px

  @media screen and (max-width: 375px)
    width: 140px

.document__key.document__key--title
  font-weight: normal
  font-size: 18px
  color: var(--border-color-grey)
  text-transform: unset

  @media screen and (max-width: 1199px)
    font-size: 16px

  @media screen and (max-width: 460px)
    font-size: 12px

.document__value
  width: 600px
  font-size: 20px
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

  @media screen and (max-width: 1439px)
    width: 350px

  @media screen and (max-width: 1199px)
    width: 260px
    font-size: 16px

  @media screen and (max-width: 767px)
    width: 200px

  @media screen and (max-width: 460px)
    font-size: 12px
    width: 150px

.document__row--second
  margin-top: 22px

.qr-code
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.success-page__qr-code
  @media screen and (max-width: 920px)
    position: absolute
    bottom: 40px
    right: 40px
  @media screen and (max-width: 767px)
    position: static

.qr-code__img
  width: 240px
  margin-bottom: 16px

  @media screen and (max-width: 1199px)
    width: 180px

.qr-code__qr-number
  font-weight: bold
  font-size: 32px
  color: var(--btn-color-blue)
  margin-bottom: 32px

  @media screen and (max-width: 920px)
    margin-bottom: 27px

.qr-code__btns-wrapper
  display: flex
  flex-direction: column
  justify-content: space-between
  gap: 24px

.qr-code__btn
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  width: 240px
  height: 50px
  color: #000
  font-size: 12px
  text-transform: uppercase
  font-weight: bold
  @media screen and (max-width: 1199px)
    width: 200px
    height: 48px

  @media screen and (max-width: 767px)
    width: 290px
    height: 44px

.qr-code__pdf-download-btn
  background: #fff
  border: 1px solid #1965FF

.qr-code__pdf-download-btn:hover
  background: rgba(25, 101, 255, 0.08)

.qr-code__pdf-download-btn:active
  background: #F3E80B

.qr-code__pcr-download-btn
  background: var(--primary-color-light-grey)

.btn__certificate
  background: var(--primary-color-yellow)
  color: #000

.btn__certificate:hover
  background-color: #E8DD07

.btn__certificate:active
  background-color: #F3E80B

.btn__test:hover
  background: #A4C7FD

.qr-code__pcr-download-btn:active
  background: #B9D4FF

.qr-code__add-wallet-btn
  color: #fff
  background: #000

.qr-code__add-wallet-btn:hover
  background: #4B4B4B

.qr-code__add-wallet-btn:active
  background: #3E3E3E

.btn__certificate
  background: var(--primary-color-yellow)
  color: #000

.btn__certificate:hover
  background-color: #E8DD07

.btn__certificate:active
  background-color: #F3E80B


.info-block
  display: flex
  flex-direction: column
  justify-content: center
  gap: 32px

.info-block__text
  max-width: 830px
  font-size: 24px
  line-height: 34px
  text-align: center
  color: var(--border-color-grey)
  padding: 0 32px

  @media screen and (max-width: 920px)
    font-size: 16px
    line-height: 24px
    max-width: 515px
    padding: 0 17px

  @media screen and (max-width: 460px)
    font-size: 14px
    line-height: 20px

.info-block__btn
  background: var(--btn-color-blue)
  color: #fff
  align-self: center
  @media screen and (max-width: 1199px)
    width: 240px
  @media screen and (max-width: 767px)
    width: 290px
    height: 44px

.info-block__btn:hover
  background: #1957C1

.info-block__btn:active
  background: #1D5DCC
</style>
